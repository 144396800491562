import React, { createContext, useContext, useState, useEffect } from 'react'
import { syncDataToBrevo } from '../utils/userDataSync'
const AuthContext = createContext()
let oldUserData = null
function AuthProvider({ children, userData, authUser, currentPlan,viewCountAndLimit }) {
  useEffect(() => {
    if (!userData) return

    if (oldUserData && oldUserData.email !== userData.email) {
      authUser
        .getIdToken()
        .then((token) => {
          syncDataToBrevo(userData, true, oldUserData.email, token)
        })
        .catch(console.error)

     
    } else {
    
      authUser
        .getIdToken()
        .then((token) => {
          syncDataToBrevo(userData, false, '', token)
        })
        .catch(console.error)
    }
    oldUserData = userData
  }, [userData])

  return (
    <AuthContext.Provider value={{ userData, authUser,currentPlan,viewCountAndLimit }}>{children}</AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth}