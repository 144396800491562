import axios from "axios";

const sendDataToCrisp = async (email,data) => {
  try{
    const apiBase = process.env.NEXT_PUBLIC_API_HOST

    const response = await axios.post(`${apiBase}/crisp/update-contact`, {email,data})
    return response

  }catch(e){
    console.error(e)
  }
}

const sendDataToKlaviyo = async (email,data) => {
  try{
    const apiBase = process.env.NEXT_PUBLIC_API_HOST

    const response = await axios.post(`${apiBase}/klaviyo/update-contact`, {email,data})
    return response

  }catch(e){
    console.error(e)
  }
}

const breakUsageCache = async (userId) => {
  const scriptHost = process.env.NEXT_PUBLIC_SCRIPT_HOST
  try{
    await axios.post(`${scriptHost}/delete-redis-usage-cache/${userId}`,{})
  }catch(e){
    console.error(e)
  }
}

const syncDataToBrevo = async (userData, changeEmail, oldEmail, token) => {
  try {
    return await axios.post(`/api/connector/brevo`, {
      userData,
      changeEmail,
      oldEmail,
      token
    })
  } catch (e) {
    console.error(e)
  }
}

const syncUninstallToBrevo = async (email) => {
  const connectorURL = process.env.API_CONNECTOR_URL
  const url = `${connectorURL}/events`

  await axios
    .post(
      url,
      {
        app: 'promolayer',
        eventType: 'promolayer_uninstall',
        eventData: { email: email }
      },
      {
        headers: { Authorization: `Bearer ${process.env.API_CONNECTOR_TOKEN}` }
      }
    )
    .catch()
}


export {
  sendDataToCrisp,
  sendDataToKlaviyo,
  breakUsageCache,
  syncDataToBrevo,
  syncUninstallToBrevo
}